import React from 'react';
import { Link, graphql } from 'gatsby';
import { localizer } from '../util/localizer';

export default function PageNotFound(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const sitedata = props.data.siteYaml;
  return (
    <div className="p-8">
      <h1 className="my-8 p-8">{sitedata.errorpage.header}</h1>
      <h2 className="my-8 p-8">{sitedata.errorpage.subheader}</h2>
      <div className="mt-40"><Link to={localized('/')} className="std-btn">{sitedata.homelink}</Link></div>
    </div>
  );
};

export const query = graphql`
  query site404Q($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      homelink
      errorpage {
        header
        subheader
      }
    }
  }`;
